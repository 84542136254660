<template>
	<div>
		<form-designer ref="formDesigner" v-model="form.fdForm"></form-designer>
	</div>
</template>

<script>



import formDesigner from "@/components/common/formDesigner/formDesigner.vue";

export default {
	name: "diy",
	components:{
		formDesigner,
	},
	data(){
		return{
			form: {
				fdForm:''
			}
		}
	},

	mounted() {
		if (this.$route.query.id && !this.$route.query.roomId) {
			this.newApi.getDiyTemplate({id: this.$route.query.id}).then(res =>{
				this.form.fdForm = JSON.stringify(res.data.json);
			})
		}
        if (this.$route.query.id && this.$route.query.roomId) {
            this.newApi.getRoomTemplateInfo({id: this.$route.query.id}).then(res =>{
                this.form.fdForm = JSON.stringify(res.data.json);
            })
        }
	}
}
</script>

<style scoped>

</style>
